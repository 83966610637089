import { CssAwareElement } from "tds_element";
import { css } from "lit";
import { customElement, property } from "lit/decorators.js";

@customElement("tds-sticky-separator")
export class StickySeparator extends CssAwareElement {
  static styles = [
    css`
      :host {
        display: block;
        height: 30px;
        background-color: #2d2d2d;
        text-decoration: none;
        color: white;
        font-weight: 600;
        text-transform: uppercase;
        width: 100%;
        top: 100px;
        font-size: 14px;
        display: flex;
        justify-content: center;
      }
      tds-sticky-separator a {
        text-decoration: none;
        color: white;
        font-weight: 600;
        text-transform: uppercase;
        margin: 0px 20px;
        font-size: 14px;
        line-height: 30px;
      }

      tds-sticky-separator a:hover {
        color: red;
      }

      @media only screen and (max-width: 768px) {
        tds-sticky-separator a:last-child {
          min-width: 50px;
          overflow: hidden;
        }
      }
    `,
  ];

  initialTop: number;
  windowYWhenSticky: number;

  constructor() {
    super();
    this.windowYWhenSticky = undefined;
    window.addEventListener("scroll", () => {
      if (this.windowYWhenSticky == undefined) {
        var rect = this.getBoundingClientRect();
        if (rect.top > 0 && rect.top <= 102) {
          this.windowYWhenSticky = new Number(window.pageYOffset) as number;
        }
      }

      if (window.pageYOffset >= this.windowYWhenSticky)
        this.style.position = "fixed";
      else this.style.position = "";
    });
  }

  firstUpdated() {
    var rect = this.getBoundingClientRect();
    this.initialTop = rect.top;
  }

  // isInViewport() {

  // 	var rect = this.getBoundingClientRect();
  // 	//console.log(rect.top, window.scrollY)
  // 	let res =
  // 		rect.top >= 0 + this.offsetTop;//|| rect.top == 100 && (window.pageYOffset <= this.initialTop || this.initialTop < this.offsetTop);
  // 	console.log(rect.top)
  // 	return res;

  // }

  @property({ type: Number })
  offsetTop: number = 100;
}

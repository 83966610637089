import { CssAwareElement } from "tds_element";
import { css, html } from "lit";
import { customElement } from "lit/decorators.js";
@customElement("tds-contact-buttons")
export class ContactButtons extends CssAwareElement {
  static styles = css`
    :host {
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
      justify-content: center;
    }

    .icons {
      margin-top: 15px;
    }

    svg {
      fill: white;
      cursor: pointer;
      margin: 0px 5px;
      -webkit-transition: fill 200ms linear;
      -ms-transition: fill 200ms linear;
      transition: fill 200ms linear;
    }

    svg:hover {
      fill: #ea4335;
    }

    a {
      font-size: 0px;
    }
  `;

  createRenderRoot() {
    /**
     * Render template without shadow DOM. Note that shadow DOM features like
     * encapsulated CSS and slots are unavailable.
     */
    return this;
  }
  render() {
    return html`
      <a href="/contact#message">
        <tds-button>MAAK KENNIS</tds-button>
      </a>

      <div class="icons">
        <a href="https://www.linkedin.com/company/traiectum-digital-solutions/">
          in
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="white"
            viewBox="0 0 50 50"
            width="25px"
            height="25px"
          >
            <path
              d="M 8 3.0097656 C 4.53 3.0097656 2.0097656 5.0892187 2.0097656 7.9492188 C 2.0097656 10.819219 4.59 12.990234 8 12.990234 C 11.47 12.990234 13.990234 10.870625 13.990234 7.890625 C 13.830234 5.020625 11.36 3.0097656 8 3.0097656 z M 3 15 C 2.45 15 2 15.45 2 16 L 2 45 C 2 45.55 2.45 46 3 46 L 13 46 C 13.55 46 14 45.55 14 45 L 14 16 C 14 15.45 13.55 15 13 15 L 3 15 z M 18 15 C 17.45 15 17 15.45 17 16 L 17 45 C 17 45.55 17.45 46 18 46 L 27 46 C 27.552 46 28 45.552 28 45 L 28 30 L 28 29.75 L 28 29.5 C 28 27.13 29.820625 25.199531 32.140625 25.019531 C 32.260625 24.999531 32.38 25 32.5 25 C 32.62 25 32.739375 24.999531 32.859375 25.019531 C 35.179375 25.199531 37 27.13 37 29.5 L 37 45 C 37 45.552 37.448 46 38 46 L 47 46 C 47.55 46 48 45.55 48 45 L 48 28 C 48 21.53 44.529063 15 36.789062 15 C 33.269062 15 30.61 16.360234 29 17.490234 L 29 16 C 29 15.45 28.55 15 28 15 L 18 15 z"
            />
          </svg>
        </a>
        <a href="mailto:info@tdscompany.nl">
          mail
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1792 1792"
            fill="white"
            width="25"
            height="25"
          >
            <path
              d="M1792 710v794q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-794q44 49 101 87 362 246 497 345 57 42 92.5 65.5t94.5 48 110 24.5h2q51 0 110-24.5t94.5-48 92.5-65.5q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123q-376 261-468 325-10 7-42.5 30.5t-54 38-52 32.5-57.5 27-50 9h-2q-23 0-50-9t-57.5-27-52-32.5-54-38-42.5-30.5q-91-64-262-182.5t-205-142.5q-62-42-117-115.5t-55-136.5q0-78 41.5-130t118.5-52h1472q65 0 112.5 47t47.5 113z"
            />
          </svg>
        </a>
      </div>
    `;
  }
}

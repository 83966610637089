import { CssAwareElement } from "tds_element";
import { css, html } from "lit";
import { customElement, property } from "lit/decorators.js";
@customElement("tds-contact-card")
export class ContactCard extends CssAwareElement {
  static styles = css`
    :host {
      display: block;
      border-bottom: #f10252 solid 3px;
    }

    .top {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: space-between;
      align-items: center;
      justify-content: space-between;
      padding-left: 30px;
    }

    .ava {
      width: 30%;
    }

    .ava img {
      width: 100%;
    }

    .names {
      width: 70%;
      padding-left: 20px;
      text-align: left;
    }

    tds-red-bold-text {
      font-weight: 800;
      font-stretch: 100%;
      font-style: normal;
      font-variant-caps: normal;
      font-variant-east-asian: normal;
      font-variant-ligatures: normal;
      font-variant-numeric: normal;
      letter-spacing: normal;
    }

    ul {
      list-style: none;
      padding-left: 30px;
      padding-right: 30px;
      font-size: 14px;
      font-weight: 600;
      text-align: left;
      color: #54595f;
    }

    ul > li {
      margin: 10px 0;
    }

    .email,
    .linkedin,
    .phone {
      padding-left: 28px;
      position: relative;
    }

    .email::before,
    .linkedin::before,
    .phone::before {
      content: "";
      display: inline-block;
      position: absolute;
      left: 0;
      top: 3px;
      width: 14px;
      height: 14px;
      background-repeat: no-repeat;
      background-size: 14px;
    }

    .email::before {
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB3aWR0aD0iMTc5MiIgaGVpZ2h0PSIxNzkyIiB2aWV3Qm94PSIwIDAgMTc5MiAxNzkyIiBmaWxsPSIjZjEwMjUyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0xNjY0IDE1MDR2LTc2OHEtMzIgMzYtNjkgNjYtMjY4IDIwNi00MjYgMzM4LTUxIDQzLTgzIDY3dC04Ni41IDQ4LjUtMTAyLjUgMjQuNWgtMnEtNDggMC0xMDIuNS0yNC41dC04Ni41LTQ4LjUtODMtNjdxLTE1OC0xMzItNDI2LTMzOC0zNy0zMC02OS02NnY3NjhxMCAxMyA5LjUgMjIuNXQyMi41IDkuNWgxNDcycTEzIDAgMjIuNS05LjV0OS41LTIyLjV6bTAtMTA1MXYtMjQuNWwtLjUtMTMtMy0xMi41LTUuNS05LTktNy41LTE0LTIuNWgtMTQ3MnEtMTMgMC0yMi41IDkuNXQtOS41IDIyLjVxMCAxNjggMTQ3IDI4NCAxOTMgMTUyIDQwMSAzMTcgNiA1IDM1IDI5LjV0NDYgMzcuNSA0NC41IDMxLjUgNTAuNSAyNy41IDQzIDloMnEyMCAwIDQzLTl0NTAuNS0yNy41IDQ0LjUtMzEuNSA0Ni0zNy41IDM1LTI5LjVxMjA4LTE2NSA0MDEtMzE3IDU0LTQzIDEwMC41LTExNS41dDQ2LjUtMTMxLjV6bTEyOC0zN3YxMDg4cTAgNjYtNDcgMTEzdC0xMTMgNDdoLTE0NzJxLTY2IDAtMTEzLTQ3dC00Ny0xMTN2LTEwODhxMC02NiA0Ny0xMTN0MTEzLTQ3aDE0NzJxNjYgMCAxMTMgNDd0NDcgMTEzeiIvPjwvc3ZnPg==");
    }
    .linkedin::before {
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB3aWR0aD0iMTc5MiIgaGVpZ2h0PSIxNzkyIiB2aWV3Qm94PSIwIDAgMTc5MiAxNzkyIiBmaWxsPSIjZjEwMjUyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0zNjUgMTQxNGgyMzF2LTY5NGgtMjMxdjY5NHptMjQ2LTkwOHEtMS01Mi0zNi04NnQtOTMtMzQtOTQuNSAzNC0zNi41IDg2cTAgNTEgMzUuNSA4NS41dDkyLjUgMzQuNWgxcTU5IDAgOTUtMzQuNXQzNi04NS41em01ODUgOTA4aDIzMXYtMzk4cTAtMTU0LTczLTIzM3QtMTkzLTc5cS0xMzYgMC0yMDkgMTE3aDJ2LTEwMWgtMjMxcTMgNjYgMCA2OTRoMjMxdi0zODhxMC0zOCA3LTU2IDE1LTM1IDQ1LTU5LjV0NzQtMjQuNXExMTYgMCAxMTYgMTU3djM3MXptNDY4LTk5OHY5NjBxMCAxMTktODQuNSAyMDMuNXQtMjAzLjUgODQuNWgtOTYwcS0xMTkgMC0yMDMuNS04NC41dC04NC41LTIwMy41di05NjBxMC0xMTkgODQuNS0yMDMuNXQyMDMuNS04NC41aDk2MHExMTkgMCAyMDMuNSA4NC41dDg0LjUgMjAzLjV6Ii8+PC9zdmc+");
    }
    .phone::before {
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB3aWR0aD0iMTc5MiIgaGVpZ2h0PSIxNzkyIiB2aWV3Qm94PSIwIDAgMTc5MiAxNzkyIiBmaWxsPSIjZjEwMjUyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0xNDA4IDExOTNxMC0xMS0yLTE2dC0xOC0xNi41LTQwLjUtMjUtNDcuNS0yNi41LTQ1LjUtMjUtMjguNS0xNXEtNS0zLTE5LTEzdC0yNS0xNS0yMS01cS0xNSAwLTM2LjUgMjAuNXQtMzkuNSA0NS0zOC41IDQ1LTMzLjUgMjAuNXEtNyAwLTE2LjUtMy41dC0xNS41LTYuNS0xNy05LjUtMTQtOC41cS05OS01NS0xNzAtMTI2LjV0LTEyNy0xNzAuNXEtMi0zLTguNS0xNHQtOS41LTE3LTYuNS0xNS41LTMuNS0xNi41cTAtMTMgMjAuNS0zMy41dDQ1LTM4LjUgNDUtMzkuNSAyMC41LTM2LjVxMC0xMC01LTIxdC0xNS0yNS0xMy0xOXEtMy02LTE1LTI4LjV0LTI1LTQ1LjUtMjYuNS00Ny41LTI1LTQwLjUtMTYuNS0xOC0xNi0ycS00OCAwLTEwMSAyMi00NiAyMS04MCA5NC41dC0zNCAxMzAuNXEwIDE2IDIuNSAzNHQ1IDMwLjUgOSAzMyAxMCAyOS41IDEyLjUgMzMgMTEgMzBxNjAgMTY0IDIxNi41IDMyMC41dDMyMC41IDIxNi41cTYgMiAzMCAxMXQzMyAxMi41IDI5LjUgMTAgMzMgOSAzMC41IDUgMzQgMi41cTU3IDAgMTMwLjUtMzR0OTQuNS04MHEyMi01MyAyMi0xMDF6bTI1Ni03Nzd2OTYwcTAgMTE5LTg0LjUgMjAzLjV0LTIwMy41IDg0LjVoLTk2MHEtMTE5IDAtMjAzLjUtODQuNXQtODQuNS0yMDMuNXYtOTYwcTAtMTE5IDg0LjUtMjAzLjV0MjAzLjUtODQuNWg5NjBxMTE5IDAgMjAzLjUgODQuNXQ4NC41IDIwMy41eiIvPjwvc3ZnPg==");
    }

    @media only screen and (max-width: 768px) {
      .top {
        flex-direction: column;
        padding-left: 0;
      }
      .ava {
        width: 160px;
      }
      .names {
        width: 100%;
        padding-left: 0;
      }
      .names {
        text-align: center;
      }
    }
  `;

  @property({ type: String })
  url: string;
  @property({ type: String })
  name: string;
  @property({ type: String })
  title: string;
  @property({ type: String })
  email: string;
  @property({ type: String })
  linkedin: string;
  @property({ type: String })
  phone: string;

  createRenderRoot() {
    /**
     * Render template without shadow DOM. Note that shadow DOM features like
     * encapsulated CSS and slots are unavailable.
     */
    return this;
  }

  render() {
    let formatPhone = this.phone;
    if (formatPhone != "") {
      let p = formatPhone;
      formatPhone = `${p[0]}${p[1]}${p[2]} (0)${p[3]}${p[4]} ${p[5]}${p[6]} ${p[7]}${p[8]} ${p[9]}${p[10]}${p[11]}`;
    }

    return html`
      <div class="top">
        <div class="ava">
          <a href="${this.linkedin}" target="_blank">
            <img src="${this.url}" alt="${this.name}" />
          </a>
        </div>
        <div class="names">
          <a href="${this.linkedin}" target="_blank">
            <tds-red-bold-text>${this.name}</tds-red-bold-text>
          </a>
          <div>
            <tds-lighter-text>${this.title}</tds-lighter-text>
          </div>
        </div>
      </div>
      <ul>
        <li><a href="mailto:${this.email}" class="email">${this.email}</a></li>
        <li>
          <a href="${this.linkedin}" target="_blank" class="linkedin"
            >LinkedIn</a
          >
        </li>
        <li><a href="tel:${this.phone}" class="phone">${formatPhone}</a></li>
      </ul>
    `;
  }
}

import { setCssValuesOverrides, TdsElement } from "tds_element";
import { installRouter } from "pwa-helpers/router";
import { UrlChanged, UrlHashChanged } from "./Messages";
import { defaultCss } from "./styles/defaultCSS";
import "./elements/stickySeparator";
import "./elements/contactButtons";
import "./elements/contactCard";
import "./elements/contactFrom";
import "./elements/contactMap";
import "tds_page_elements";
import "tds_menu";
import { overrides } from "./styles/cssValuesOverrides";

import { html, css } from "lit";
import { customElement, property } from "lit/decorators.js";

import { getMsgState } from "stateful-messaging-system";
setCssValuesOverrides(overrides);

@customElement("tds-app")
class TdsApp extends TdsElement {
  static styles = [
    defaultCss,
    css`
      .top-bar {
        position: fixed;
        top: 0px;
        width: 100%;
        background-color: transparent;
        z-index: 9999;
        color: white;
        --top-bar-color: white;
        box-sizing: border-box;
        -webkit-transition: background-color 300ms linear;
        -ms-transition: background-color 300ms linear;
        transition: background-color 300ms linear;
      }
      .top-bar-inner {
        min-height: 100px;
        max-height: 125px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        padding: 0px 8px 0px 50px;
        max-width: 1240px;
        margin: 0 auto;
      }
      .top-bar[scrolled] {
        background-color: white;
        box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.14);
      }

      .logo {
        /* background-image: url("/images/manifest/logo48.svg");
			background-position: center;
    		background-repeat: no-repeat; */
        border-radius: 20%;
        width: 70px;
        min-width: 70px;
        height: 70px;
      }
      .top-bar[scrolled] {
        --top-bar-color: #474747;
        color: #474747;
      }

      .page {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        text-align: center;
        align-items: center;
      }

      h2 {
        color: #282828;
        font-weight: 600;
        font-size: 50px;
        letter-spacing: -3px;
      }

      .footer {
        display: block;
        height: 115px;
        width: 100%;
        background-color: #2d2d2d;
        color: white;
        display: flex;
        justify-content: center;
        font-size: 12px;
        align-content: center;
        align-items: center;
        bottom: 0;
      }

      .footer > div {
        margin: 20px;
      }

      .p404 {
        box-sizing: border-box;
        padding-top: 200px;
        text-align: center;
      }

      .scroll-top {
        display: none;
        justify-content: center;
        align-items: center;
        position: fixed;
        right: 20px;
        bottom: 20px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        background-color: rgba(0, 0, 0, 0.4);
        color: #fff;
        font-size: 18px;
        cursor: pointer;
        border-radius: 2px;
        z-index: 9999;
        box-sizing: content-box;
      }
      .scroll-top[visible] {
        display: inline-flex;
      }
      @media only screen and (max-width: 768px) {
        .top-bar-inner {
          padding: 0 15px;
        }
      }
    `,
  ];

  @property({ type: Boolean })
  __scrolled: boolean = false;
  page: any;

  constructor() {
    super();

    window.addEventListener("scroll", this.updateScrolled.bind(this));
    installRouter((location, e) => {
      let last = getMsgState(UrlChanged);
      let lastH = getMsgState(UrlHashChanged);
      let diffH = !lastH || last.hash != location.hash;
      let diffP = !last || last.location != location.pathname;
      if (diffH) new UrlHashChanged(location).send();

      if (diffP) new UrlChanged(location).send();
    });
    this.subscribe(UrlChanged, this.urlChanged);
    this.subscribe(UrlHashChanged, this.hashChanged);
    new UrlChanged(location).send();
  }
  updateScrolled() {
    this.__scrolled = window.pageYOffset > 100;
  }

  async hashChanged(msg: UrlHashChanged) {
    if (!msg || msg.hash == "" || msg.hash == "#" || msg.hash == undefined)
      return;
    let el = this.renderRoot.querySelector(msg.hash);
    if (el) {
      el.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }

  async urlChanged(msg: UrlChanged) {
    let page;
    if (msg == null || msg.parts.length == 0) page = "home";
    else page = msg.parts[0];

    this.page = page;
    await fetch(`/pages/${page}.html`).then((r) => this.handleFetchResult(r));

    await this.requestUpdate();

    this.updateFooter();
    let anchors = (<unknown>(
      this.renderRoot.querySelectorAll("a[href^='#']")
    )) as HTMLAnchorElement[];
    for (let a of anchors) {
      a.href = location.pathname + a.attributes["href"].value;
    }
  }

  async handleFetchResult(r: Response) {
    if (r.ok)
      document.getElementById("page_content").innerHTML = await r.text();
    else this.renderNotFound();
  }

  async renderNotFound() {
    this.__scrolled = true;
    document.getElementById("page_content").innerHTML =
      "<h2 class='p404'>Ooops... page not found :(</h2>";
  }

  updateFooter() {
    let relative = document.body.clientHeight > window.innerHeight;
    let footer = this.renderRoot.querySelector(".footer") as HTMLElement;
    if (footer) footer.style.position = relative ? "relative" : "fixed";
  }

  updated() {
    this.updateFooter();
  }

  bHtml: string = "";

  render() {
    // return html`<tds-centered-block> <div>test</div> </tds-centered-block>`
    return html`
      <div class="top-bar" ?scrolled=${this.__scrolled}>
        <div class="top-bar-inner">
          <a href="/"
            ><img alt="TDS" src="/images/manifest/logo48.svg" class="logo"
          /></a>
          <tds-main-menu>
            <a is="tds-menu-item" page="/" href="/">home</a>
            <a is="tds-menu-item" page="services" href="/services">diensten</a>
            <a is="tds-menu-item" page="cases" href="/cases">cases</a>
            <a is="tds-menu-item" page="about" href="/about">over ons</a>
            <a is="tds-menu-item" page="contact" href="/contact"> contact </a>
          </tds-main-menu>
        </div>
      </div>

      <div id="page_content"></div>
      <div class="footer">
        <div>TDS © 2023</div>
        <div>KvK nummer: 70517266</div>
        <div>BTW nummer: NL858354834b01</div>
      </div>

      <span
        @click=${() => window.scroll({ top: 0, left: 0, behavior: "smooth" })}
        ?visible=${this.__scrolled}
        class="scroll-top"
      >
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 24 24"
          fill="white"
          width="24px"
        >
          <g>
            <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z"></path>
          </g>
        </svg>
      </span>
    `;
  }
}
